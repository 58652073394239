html,
body {
    position: relative;
    margin: 0;
    font-family: "微软雅黑", "思源黑体", "黑体";
    -webkit-user-drag: none;
    user-select: none;
    width: 100%;
    height: 100%;
}
html, body, ul, li, ol, dl, dd, dt, p, h1, h2, h3, h4, h5, h6, form, fieldset, legend, img {
    margin: 0;
    padding: 0;
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.clearfix:before, .clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1; /*IE/7/6*/
}


h1, h2, h3, h4, h5, h6 {
    text-decoration: none;
    font-weight: normal;
    font-size: 100%;
}

s, i, em {
    font-style: normal;
    text-decoration: none;
}





