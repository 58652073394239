
html {
         font-size: 13.333vw;
      // 同时，通过Media Queries 限制根元素最大最小值
       @media screen and (max-width: 320px) {
             font-size: 32px;
         }
       @media screen and (min-width: 750px) {
             font-size: 100px;
         }
         
     }
// body 也增加最大最小宽度限制，避免默认100%宽度的 block 元素跟随 body 而过大过小
body.mobile {
          max-width: 750px;
          min-width: 320px;
            margin: 0 auto;
}
a[title="站长统计"] {
 display: none;
}
.page-loading-warp {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22PX;
  height: 100%;
  flex-direction: column;
  .ant-spin {
    position: absolute;
    display: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    color: #1890ff;
    font-size: 14PX;
    font-variant: tabular-nums;
    line-height: 1.5;
    text-align: center;
    list-style: none;
    opacity: 0;
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
  }

  .ant-spin-spinning {
    position: static;
    display: inline-block;
    opacity: 1;
  }

  .ant-spin-dot {
    position: relative;
    display: inline-block;
    width: 20PX;
    height: 20PX;
    font-size: 20PX;
  }

  .ant-spin-dot-item {
    position: absolute;
    display: block;
    width: 9PX;
    height: 9PX;
    background-color: #1890ff;
    border-radius: 100%;
    -webkit-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 0.3;
    -webkit-animation: antspinmove 1s infinite linear alternate;
    animation: antSpinMove 1s infinite linear alternate;
  }

  .ant-spin-dot-item:nth-child(1) {
    top: 0;
    left: 0;
  }

  .ant-spin-dot-item:nth-child(2) {
    top: 0;
    right: 0;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }

  .ant-spin-dot-item:nth-child(3) {
    right: 0;
    bottom: 0;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }

  .ant-spin-dot-item:nth-child(4) {
    bottom: 0;
    left: 0;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
  }

  .ant-spin-dot-spin {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: antrotate 1.2s infinite linear;
    animation: antRotate 1.2s infinite linear;
  }

  .ant-spin-lg .ant-spin-dot {
    width: 32PX;
    height: 32PX;
    font-size: 32PX;
  }

  .ant-spin-lg .ant-spin-dot i {
    width: 14PX;
    height: 14PX;
  }
  .page-spin-text{
    height: 60PX;
    font-size: 18PX;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc
  }

}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}

@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}